import { useState, useEffect, useRef } from 'react';
/**
 * Custom hook that tracks whether an element is visible in the viewport
 * @param offset Margin offset in pixels (can be negative to trigger before element is visible)
 * @returns [ref, isVisible] - A ref to attach to the element and a boolean indicating visibility
 */
export function useVisible(offset) {
    if (offset === void 0) { offset = 0; }
    var _a = useState(false), isVisible = _a[0], setIsVisible = _a[1];
    var ref = useRef(null);
    useEffect(function () {
        var element = ref.current;
        if (!element)
            return;
        var observer = new IntersectionObserver(function (entries) {
            var entry = entries[0];
            setIsVisible(entry.isIntersecting);
        }, {
            rootMargin: "".concat(offset, "px")
        });
        observer.observe(element);
        return function () {
            observer.disconnect();
        };
    }, [offset]);
    return [ref, isVisible];
}
export default useVisible;
